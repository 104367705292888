import Alpine from "alpinejs";
import intersect from "@alpinejs/intersect";
// import collapse from "@alpinejs/collapse";
// import persist from "@alpinejs/persist";
// import mask from "@alpinejs/mask";

export const alpineSetup = () => {
	Alpine.plugin(intersect);
	// Alpine.plugin(collapse);
	// Alpine.plugin(persist);
	// Alpine.plugin(mask);

	window.Alpine = Alpine;
	Alpine.start();
};

export default alpineSetup;
